import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"

// Components
import Seo from "components/seo"
import Hero from "../../components/hero"

// Page sections
import Featured from "../screens/resources/webinars/featured"
import List from "../screens/resources/webinars/list"

const Webinars = () => {
	const data = useStaticQuery(graphql`
    query {
        allWpWebinar(filter: {webinars: {featured: {eq: true}}}) {
          totalCount
        }
      }
  `)
  return (
    <React.Fragment>
      <Hero pageTitle="Webinars">
        <p>Learn the latest approaches to managing reproductive and respiratory health from thought leaders.</p><p className="bold">CE credits available on select webinars. All webinars are free.</p>
      </Hero>
     { data.allWpWebinar.totalCount > 0 ? (<Featured />) : null }
      <List /> 
    </React.Fragment>
  )
}
export default Webinars

export const Head = () => (
  <Seo
        title="Webinars"
        description="Learn the latest approaches to managing reproductive and respiratory health from thought leaders. CE credits available on select webinars. All webinars are free."
        image="/meta/insights-webinars.jpg"
  />
)
